import Vue from 'vue'
import Clipboard from 'clipboard'


function clipboardError() {
  Vue.prototype.$Message.error('Copy failed')
}

export default function handleClipboard(text, event, callback) {
  const clipboard = new Clipboard(event.target, {
    text: () => text
  })
  clipboard.on('success', () => {
    callback()
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    clipboardError()
    clipboard.destroy()
  })
  clipboard.onClick(event)
}
