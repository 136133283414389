<template>
  <div class="wallets_content">
    <div class="desc_box">
      <div class="title">快捷生成多個冷錢包地址</div>
      <div class="desc_item">
        <img src="../assets/img/w1.png" class="icon" srcset="" />
        <div>
          <div class="desc_title">生成冷钱包</div>
          <div class="desc_text">批量生成EVM兼容链钱包地址</div>
        </div>
      </div>
      <div class="desc_item">
        <img src="../assets/img/w2.png" class="icon" srcset="" />
        <div>
          <div class="desc_title">備份信息</div>
          <div class="desc_text">備份錢包私鑰隱私信息</div>
        </div>
      </div>
      <div class="desc_item">
        <img src="../assets/img/w3.png" class="icon" srcset="" />
        <div>
          <div class="desc_title">導出錢包</div>
          <div class="desc_text">導出錢包時服務器不會存儲私鑰</div>
        </div>
      </div>
      <div class="desc_bg"></div>
    </div>
    <div class="main_box">
      <div class="base_title base_title_wallet">生成數量</div>
      <div class="base_input mt20">
        <input type="text" placeholder="輸入生成數量" v-model="num"/>
      </div>
      <div class="main_btns">
        <div class="btn btn_bg" @click="createWallet">
          <img src="../assets/img/w4.png" alt="" srcset="" />批量生成地址
        </div>
        <div class="btn btn_bd" @click="clickDownload">
          <img src="../assets/img/w5.png" alt="" srcset="" />導出地址
        </div>
        <!-- <download-excel
            :data="wallets"
            :fields="json_fields"
            :before-generate="startDownload"
            :before-finish="finishDownload"
            name="wallets.xls"
            class="btn btn_bd"
          >
            <img src="../assets/img/w5.png" alt="" srcset="" />導出地址
        </download-excel> -->
      </div>
      <div class="base_title base_title_wallet" v-show="wallets.length">
        地址信息
      </div>
      <div class="wallets_list">
        <div class="wallets_item" v-for="(item, index) in wallets" :key="index">
          <div class="wallets_item_info">
            <div class="left_info">
              <span class="left_label">地址</span>
              <span class="left_text">{{item.address}}</span>
            </div>
            <img src="../assets/img/w6.png" @click="clipboardSuccess($event, item.address)" class="copy_icon" srcset="" />
          </div>
          <div class="wallets_item_info">
            <div class="left_info">
              <span class="left_label">私鑰</span>
              <span class="left_text"
                >{{item.privateKey}}</span
              >
            </div>
            <img src="../assets/img/w6.png" @click="clipboardSuccess($event, item.privateKey)" class="copy_icon" srcset="" />
          </div>
          <div class="wallets_item_info">
            <div class="left_info">
              <span class="left_label">助記詞</span>
              <span class="left_text"
                >{{item.mnemonic}}</span
              >
            </div>
            <img src="../assets/img/w6.png" @click="clipboardSuccess($event, item.mnemonic)" class="copy_icon" srcset="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";
import clip from "../utils/clipboard";
import { ethers } from "ethers";
export default {
  props: {
    address: {
      type: String,
    }
  },
  data() {
    return {
      num: "",
      wallets: [],
      json_fields: {
        address: "address",
        mnemonic: "mnemonic",
        privateKey: "privateKey",
      }
    };
  },

  created() {},
  methods: {
    startDownload() {
      if(this.wallets.length) {
        this.msg = this.$Message.loading({
          content: "Loading...",
          duration: 0,
        });
      }
      
    },
    finishDownload() {
      if(this.msg) {
        this.msg()
      }
    },
    clickDownload() {
      var ws = XLSX.utils.json_to_sheet(this.wallets); //将json转成表对象
      var workbook = {	//定义操作文档
          SheetNames: ['sheet'], // 定义表明
          Sheets: {
              'sheet': ws 	//表对象[注意表明]
          }
      };
      XLSX.write(workbook,{bookType:'xlsx', bookSST:true, type: 'base64'}); 	//将数据写入文件中
      XLSX.writeFile(workbook,'wallet.xlsx');	//导出文件

    },
    createWallet() {
      if (!this.num) {
        this.$message.error("請輸入要创建的钱包数量");
        return;
      }
      this.message = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
      let walletList = [];
      try {
        setTimeout(() => {
          let num = parseInt(this.num);
          for (let i = 0; i < num; i++) {
            //拿到生成的钱包对象
            let wallet = ethers.Wallet.createRandom();
            console.log("wallet：", wallet);
            //获取助记词对象
            let mnemonic = wallet.mnemonic;
            //助记词
            let phrase = mnemonic.phrase;
            //获取钱包的私钥
            let privateKey = wallet.privateKey;
            //获取钱包地址
            let address = wallet.address;
            walletList.push({
              mnemonic: phrase,
              privateKey: privateKey,
              address: address,
            });
          }
          this.wallets = walletList;
          this.message.close()
        }, 500);

        // this.setState({ wallets: wallets, address: address });
      } catch (e) {
        console.log("e", e);
        this.message.close()
      } finally {
        console.log(111111);
        // msg2();
      }
    },
    clipboardSuccess(event, text) {
      var that = this;
      clip(text, event, function () {
        that.$Message.success(that.$t("lang.success1"));
      });
    }
  },
};
</script>

<style lang="less">
@import "../assets/pc/wallet.less";
@import "../assets/mobile/wallet.less";
</style>
